import React from 'react';
import "../styles/waveseparator.scss"

const WaveSeparator = () => {
    return (
    <div className='wave-container'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" preserveAspectRatio='none' width='1920' height='1080'><g transform="
          rotate(0 960 540) 
          translate(-0 -0)
          scale(1)
        " >
        <rect width="1920" height="1080" fill="rgb(0, 0, 0, 0)"></rect>
        {/* <g transform="translate(0, 180)"><path fill="rgb(36, 85, 56)" fill-opacity="0.75" d="M0,100.128L29.091,94.61C58.182,89.092,116.364,78.056,174.545,61.938C232.727,45.82,290.909,24.62,349.091,33.454C407.273,42.288,465.455,81.157,523.636,86.779C581.818,92.401,640,64.775,698.182,71.182C756.364,77.588,814.545,118.027,872.727,133.177C930.909,148.327,989.091,138.188,1047.273,120.912C1105.455,103.637,1163.636,79.224,1221.818,77.441C1280,75.659,1338.182,96.507,1396.364,104.128C1454.545,111.749,1512.727,106.142,1570.909,107.771C1629.091,109.4,1687.273,118.264,1745.455,112.26C1803.636,106.255,1861.818,85.381,1890.909,74.945L1920,64.508L1920,900L1890.909,900C1861.818,900,1803.636,900,1745.455,900C1687.273,900,1629.091,900,1570.909,900C1512.727,900,1454.545,900,1396.364,900C1338.182,900,1280,900,1221.818,900C1163.636,900,1105.455,900,1047.273,900C989.091,900,930.909,900,872.727,900C814.545,900,756.364,900,698.182,900C640,900,581.818,900,523.636,900C465.455,900,407.273,900,349.091,900C290.909,900,232.727,900,174.545,900C116.364,900,58.182,900,29.091,900L0,900Z"></path></g> */}
        {/* <g transform="translate(0, 360)"><path fill="rgb(71, 128, 94)" fill-opacity="0.9" d="M0,158.698L29.091,145.135C58.182,131.571,116.364,104.444,174.545,104.809C232.727,105.174,290.909,133.031,349.091,140.978C407.273,148.926,465.455,136.963,523.636,119.488C581.818,102.014,640,79.028,698.182,58.631C756.364,38.234,814.545,20.425,872.727,17.467C930.909,14.51,989.091,26.403,1047.273,29.126C1105.455,31.85,1163.636,25.403,1221.818,26.219C1280,27.035,1338.182,35.113,1396.364,33.859C1454.545,32.605,1512.727,22.02,1570.909,14.901C1629.091,7.781,1687.273,4.128,1745.455,17.848C1803.636,31.567,1861.818,62.658,1890.909,78.203L1920,93.749L1920,720L1890.909,720C1861.818,720,1803.636,720,1745.455,720C1687.273,720,1629.091,720,1570.909,720C1512.727,720,1454.545,720,1396.364,720C1338.182,720,1280,720,1221.818,720C1163.636,720,1105.455,720,1047.273,720C989.091,720,930.909,720,872.727,720C814.545,720,756.364,720,698.182,720C640,720,581.818,720,523.636,720C465.455,720,407.273,720,349.091,720C290.909,720,232.727,720,174.545,720C116.364,720,58.182,720,29.091,720L0,720Z"></path></g> */}
        <g transform="translate(0, 540)"><path fill="rgb(121, 170, 141)" fillOpacity="1" d="M0,129.697L29.091,115.25C58.182,100.803,116.364,71.909,174.545,52.458C232.727,33.007,290.909,22.999,349.091,36.421C407.273,49.843,465.455,86.695,523.636,89.268C581.818,91.842,640,60.136,698.182,62.841C756.364,65.547,814.545,102.664,872.727,104.691C930.909,106.718,989.091,73.655,1047.273,62.966C1105.455,52.277,1163.636,63.962,1221.818,78.803C1280,93.643,1338.182,111.638,1396.364,111.358C1454.545,111.079,1512.727,92.524,1570.909,76.29C1629.091,60.056,1687.273,46.142,1745.455,36.932C1803.636,27.721,1861.818,23.215,1890.909,20.962L1920,18.709L1920,540L1890.909,540C1861.818,540,1803.636,540,1745.455,540C1687.273,540,1629.091,540,1570.909,540C1512.727,540,1454.545,540,1396.364,540C1338.182,540,1280,540,1221.818,540C1163.636,540,1105.455,540,1047.273,540C989.091,540,930.909,540,872.727,540C814.545,540,756.364,540,698.182,540C640,540,581.818,540,523.636,540C465.455,540,407.273,540,349.091,540C290.909,540,232.727,540,174.545,540C116.364,540,58.182,540,29.091,540L0,540Z"></path></g>
        {/* <g transform="translate(0, 720)"><path fill="rgb(185, 213, 196)" fill-opacity="1" d="M0,110.52L29.091,96.038C58.182,81.557,116.364,52.594,174.545,35.145C232.727,17.697,290.909,11.763,349.091,34.548C407.273,57.334,465.455,108.838,523.636,113.019C581.818,117.2,640,74.057,698.182,56.814C756.364,39.571,814.545,48.229,872.727,69.896C930.909,91.564,989.091,126.242,1047.273,139.186C1105.455,152.13,1163.636,143.34,1221.818,145.044C1280,146.748,1338.182,158.946,1396.364,141.743C1454.545,124.54,1512.727,77.937,1570.909,64.718C1629.091,51.498,1687.273,71.662,1745.455,86.848C1803.636,102.033,1861.818,112.241,1890.909,117.344L1920,122.448L1920,360L1890.909,360C1861.818,360,1803.636,360,1745.455,360C1687.273,360,1629.091,360,1570.909,360C1512.727,360,1454.545,360,1396.364,360C1338.182,360,1280,360,1221.818,360C1163.636,360,1105.455,360,1047.273,360C989.091,360,930.909,360,872.727,360C814.545,360,756.364,360,698.182,360C640,360,581.818,360,523.636,360C465.455,360,407.273,360,349.091,360C290.909,360,232.727,360,174.545,360C116.364,360,58.182,360,29.091,360L0,360Z"></path></g> */}
        <g transform="translate(0, 900)"><path fill="rgb(255, 255, 255)" fillOpacity="1" d="M0,149.28L29.091,136.917C58.182,124.554,116.364,99.828,174.545,79.384C232.727,58.941,290.909,42.78,349.091,50.707C407.273,58.634,465.455,90.649,523.636,110.83C581.818,131.012,640,139.36,698.182,123.109C756.364,106.859,814.545,66.009,872.727,55.246C930.909,44.483,989.091,63.808,1047.273,62.156C1105.455,60.505,1163.636,37.879,1221.818,38.022C1280,38.166,1338.182,61.08,1396.364,61.387C1454.545,61.695,1512.727,39.396,1570.909,43.756C1629.091,48.115,1687.273,79.133,1745.455,87.266C1803.636,95.399,1861.818,80.648,1890.909,73.272L1920,65.896L1920,180L1890.909,180C1861.818,180,1803.636,180,1745.455,180C1687.273,180,1629.091,180,1570.909,180C1512.727,180,1454.545,180,1396.364,180C1338.182,180,1280,180,1221.818,180C1163.636,180,1105.455,180,1047.273,180C989.091,180,930.909,180,872.727,180C814.545,180,756.364,180,698.182,180C640,180,581.818,180,523.636,180C465.455,180,407.273,180,349.091,180C290.909,180,232.727,180,174.545,180C116.364,180,58.182,180,29.091,180L0,180Z"></path></g></g></svg>
    </div>
      )
  };
  
export default WaveSeparator;